@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'LynkcoType-Light';
  src: local('LynkcoType-Light'),
  url('./fonts/LynkcoType-Light.woff') format('woff'),
  url('./fonts/LynkcoType-Regular.woff2') format('woff2');
  font-weight: light;
  font-style: light;
}

@font-face {
  font-family: 'LynkcoType-Regular';
  src: local('LynkcoType-Regular'),
  url('./fonts/LynkcoType-Regular.woff') format('woff'),
  url('./fonts/LynkcoType-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'LynkcoType-Medium';
  src: local('LynkcoType-Medium'),
  url('./fonts/LynkcoType-Medium.woff') format('woff'),
  url('./fonts/LynkcoType-Medium.woff2') format('woff2');
  font-weight: medium;
  font-style: medium;
}

@font-face {
  font-family: 'LynkcoType-Bold';
  src: local('LynkcoType-Bold'),
  url('./fonts/LynkcoType-Bold.woff') format('woff'),
  url('./fonts/LynkcoType-Bold.woff2') format('woff2');
}

.text-lynk-heading-large {
  font-family: 'LynkcoType-Medium';
  line-height: 28px;
}

.text-lynk-heading-small {
  font-family: 'LynkcoType-Medium';
  line-height: 20px;
}

.text-lynk-body-regular {
  font-family: 'LynkcoType-Regular';
  line-height: 18px;
}

.text-lynk-body-medium {
  font-family: 'LynkcoType-Medium';
  line-height: 18px;
}

.text-lynk-body-medium-regular {
  font-family: 'LynkcoType-Regular';
  line-height: 20px;
}

.text-lynk-turquoise-500 {
  color: #1EF1C6
}

.text-lynk-neutral-200 {
  color: #B3B3B3
}

.bg-lynk-neutral-400 {
  background-color: #464646;
  border-color: #464646;
}

.bg-lynk-neutral-500 {
  background-color: #393939;
}

.bg-lynk-neutral-700 {
  background-color: #262626;
  border-color: #262626;
}

.bg-lynk-turquoise-500 {
  background-color: #1EF1C6;
}

.bg-lynk-turquoise-700 {
  background-color: #099E80;
}

.body-description span.highlight {
  font-family: 'LynkcoType-Medium';
  color: #fff;
}

.body-description span.subheading {
  display: block;
  font-family: 'LynkcoType-Medium';
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 4px;
  color: #fff;
}

.animation-shimmer-horizontal {
  background: linear-gradient(90deg, #1EF1C6, #1EF1C6, #26c0fc, #1EF1C6, #1EF1C6);
  background-size: 400% 400%;
  background-color: transparent;
  border-color:  transparent !important;

  -webkit-animation: AnimationName 3s ease infinite;
  -moz-animation: AnimationName 3s ease infinite;
  animation: AnimationName 3s ease infinite;
}

@-webkit-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}








.bg-lynk-grey-500 {
  background-color: #424242;
}

.bg-lynk-black {
  background-color: #262626;
}

.btn-primary {
  border-color: #1EF1C6;
}

.btn-primary:active {
  background-color: #099E80;
  border-color: #099E80;
}

.btn-secondary {
  
}

.btn-secondary:active {
  opacity: 0.4;
}




img {
  object-fit: cover; /* bug fix for Safari */
}

article img {
  object-fit: contain;
}


/* fixes tables to have full width and also overflow x working */
tbody {
  display: inline-table;
  width: 100%;
}



/* custom Lynk & Co style lists */
#listItem::after {
  content: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6.00111L1.00278 11' stroke='%23808080' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  width: 25px;
  height: 25px;
}




html {
  background-color: #262626;
  font-family: 'LynkcoType-Regular';
}

body {
  background-color: #262626;
  font-family: 'LynkcoType-Regular';
}

.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.snap-always {
  scroll-snap-stop: always;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
